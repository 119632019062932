import { create } from "zustand";
import { callWebToAppFunction } from "../utils/androidCallers";
import { EventTypes, WebToApp } from "../constants/Constants";
import useAdUnitStore from "./useAdUnitStore";
import { EventInfo } from "../types/window";
import { AdFormat } from "../constants/AdConstants";
import useSessionStore from "./useSessionStore";
import useEnvStore from "./useEnvStore";

type AdState = {
  adLoadAttempt: number;
  maxLoadAttempts: number;
  adLoadAttempts: {
    [key: string]: number;
  };
  isInsAdLoadedStatus: {
    [key: string]: boolean;
  };
  temporaryPlacementFlag: string;
  temporaryPlacementFlag_replay: string;
  totalAdsInCache: number;
  adLoadFailedDueToOffline: boolean;
  adLoadFailedPlacement: string;
  adLoadRetryFunc: () => void;
  incrementTotalAdsInCache: () => void;
  incrementLoadAttempts: (adPlacement: string) => void;
  resetLoadAttempts: (adPlacement: string) => void;
  setInsAdLoaded: (adPlacement: string, loaded: boolean) => void;
  setInsAdLoadRetryFunc: (adPlacement: string) => void;
  setRdAdLoadRetryFunc: (adPlacement: string) => void;
  loadInsAd: (adPlacement: string) => void;
  loadRdAd: (adPlacement: string) => void;
  retryLoadingInsAd: (adPlacement: string) => void;
  showInsAd: (adPlacement: string) => void;
  showRdAd: (adPlacement: string) => void;
  checkInsAdLoaded: (adPlacement: string) => boolean;
  checkRdAdLoaded: (adPlacement: string) => boolean;
  setTemporaryPlacementFlag: (flagVal: string) => void;
  setTemporaryPlacementFlag_replay: (flagVal: string) => void;
};
const useAdStore = create<AdState>()((set, get) => ({
  adLoadAttempt: 0,
  maxLoadAttempts: 3,
  adLoadAttempts: {},
  isInsAdLoadedStatus: {},
  temporaryPlacementFlag: "",
  totalAdsInCache: 0,
  temporaryPlacementFlag_replay: "",
  adLoadFailedDueToOffline: false,
  adLoadFailedPlacement: "",
  adLoadRetryFunc: () => {},
  incrementTotalAdsInCache: () =>
    set((prev) => ({ totalAdsInCache: prev.totalAdsInCache + 1 })),
  incrementLoadAttempts: (adPlacement: string) =>
    set((state) => ({
      adLoadAttempts: {
        ...state.adLoadAttempts,
        [adPlacement]: (state.adLoadAttempts[adPlacement] ?? 1) + 1,
      },
    })),
  decrementTotalAdsInCache: () =>
    set((prev) => ({ totalAdsInCache: prev.totalAdsInCache - 1 })),
  resetLoadAttempts: (adPlacement: string) =>
    set((state) => ({
      adLoadAttempts: {
        ...state.adLoadAttempts,
        [adPlacement]: 1,
      },
    })),
  setInsAdLoaded: (adPlacement, loaded) =>
    set((state) => ({
      isInsAdLoadedStatus: {
        ...state.isInsAdLoadedStatus,
        [adPlacement]: loaded,
      },
    })),
  setInsAdLoadRetryFunc: (adPlacement) =>
    set((prev) => ({
      adLoadFailedDueToOffline: true,
      adLoadFailedPlacement: adPlacement,
      adLoadRetryFunc: () => {
        set({ adLoadFailedDueToOffline: false });
        prev.loadInsAd(adPlacement);
      },
    })),
  setRdAdLoadRetryFunc: (adPlacement) =>
    set((prev) => ({
      adLoadFailedDueToOffline: true,
      adLoadFailedPlacement: adPlacement,
      adLoadRetryFunc: () => {
        set({ adLoadFailedDueToOffline: false });
        prev.loadRdAd(adPlacement);
      },
    })),
  loadInsAd: (adPlacement) => {
    console.log(
      "webToAdCall calling load Ins ad inside useAdStore: ",
      adPlacement,
    );
    const adUnitLoadInsAd = useAdUnitStore
      .getState()
      .getAdUnitByPlacement(adPlacement)!;

    const game = useSessionStore.getState().currentGame;
    const onInsAdSuccessEvent: EventInfo = {
      eventType: EventTypes.ad_load,
      eventProperties: {
        web_timestamp: Date.now(),
        internetstate: useEnvStore.getState().isOnline ? "online" : "offline",
        ad_type: AdFormat.Interstitial,
        game_name: game?.name,
        game_id: game?.id,
        placement: adPlacement,
        mediation: adUnitLoadInsAd.adClient,
        game_session_id: useSessionStore.getState().gameSessionId,
        ad_unit: adUnitLoadInsAd.adUnitId,
      },
    };
    // console.log("On ins ad loaded successfuly line 3");
    callWebToAppFunction(
      WebToApp.ANALYTICS_LISTENER,
      "",
      "",
      "",
      onInsAdSuccessEvent,
      null,
      undefined,
    );
    callWebToAppFunction(
      WebToApp.LOAD_INS_AD,
      adUnitLoadInsAd.adClient,
      adUnitLoadInsAd.adUnitId,
      adPlacement,
    );
  },
  loadRdAd: (adPlacement) => {
    console.log(
      "webToAdCall calling load Rd ad inside useAdStore: ",
      adPlacement,
    );
    const adUnitLoadRdAd = useAdUnitStore
      .getState()
      .getAdUnitByPlacement(adPlacement)!;

    const game = useSessionStore.getState().currentGame;
    const onInsAdSuccessEvent: EventInfo = {
      eventType: EventTypes.ad_load,
      eventProperties: {
        web_timestamp: Date.now(),
        internetstate: useEnvStore.getState().isOnline ? "online" : "offline",
        ad_type: AdFormat.Rewarded,
        game_name: game?.name,
        game_id: game?.id,
        placement: adPlacement,
        mediation: adUnitLoadRdAd.adClient,
        game_session_id: useSessionStore.getState().gameSessionId,
        ad_unit: adUnitLoadRdAd.adUnitId,
      },
    };
    // console.log("On ins ad loaded successfuly line 3");
    callWebToAppFunction(
      WebToApp.ANALYTICS_LISTENER,
      "",
      "",
      "",
      onInsAdSuccessEvent,
      null,
      undefined,
    );
    callWebToAppFunction(
      WebToApp.LOAD_RD_AD,
      adUnitLoadRdAd.adClient,
      adUnitLoadRdAd.adUnitId,
      adPlacement,
    );
  },
  retryLoadingInsAd: (adPlacement) => {
    // const { maxLoadAttempts, loadInsAd, incrementLoadAttempts } = get();
    // if (get().adLoadAttempt < maxLoadAttempts) {
    //   console.log("Ins ad retry attempt:", get().adLoadAttempt);
    //   // const adUnitGameStart = useAdUnitStore
    //   //   .getState()
    //   //   .getAdUnitByPlacement(adPlacement);
    //   loadInsAd(adPlacement);
    //   incrementLoadAttempts();
    // } else console.log("Ins ad retry has failed for ins ad:");
  },
  showInsAd: (adPlacement) => {
    console.log(
      "webToAdCall calling SHOW Ins ad inside useAdStore: ",
      adPlacement,
    );
    const adUnitShowInsAd = useAdUnitStore
      .getState()
      .getAdUnitByPlacement(adPlacement)!;
    // callWebToAppFunction(
    //   WebToApp.SHOW_INS_AD,
    //   AdClients.AppLovin,
    //   AdUnitIds.AppLovin.game_start_fsi,
    //   AdPlacement.game_start_fsi
    // );
    // console.log(
    //   "calling show Ins ad inside useAdStore",
    //   adPlacement,
    //   adUnitShowInsAd?.adClient,
    //   adUnitShowInsAd?.adUnitId
    // );
    callWebToAppFunction(
      WebToApp.SHOW_INS_AD,
      adUnitShowInsAd.adClient,
      adUnitShowInsAd.adUnitId,
      adPlacement,
    );
  },
  showRdAd: (adPlacement) => {
    console.log(
      "webToAdCall calling SHOW Rd ad inside useAdStore: ",
      adPlacement,
    );
    const adUnitShowRdAd = useAdUnitStore
      .getState()
      .getAdUnitByPlacement(adPlacement)!;
    // callWebToAppFunction(
    //   WebToApp.SHOW_INS_AD,
    //   AdClients.AppLovin,
    //   AdUnitIds.AppLovin.game_start_fsi,
    //   AdPlacement.game_start_fsi
    // );
    // console.log(
    //   "calling show Ins ad inside useAdStore",
    //   adPlacement,
    //   adUnitShowInsAd?.adClient,
    //   adUnitShowInsAd?.adUnitId
    // );
    callWebToAppFunction(
      WebToApp.SHOW_RD_AD,
      adUnitShowRdAd.adClient,
      adUnitShowRdAd.adUnitId,
      adPlacement,
    );
  },
  checkInsAdLoaded: (adPlacement) => {
    // console.log("**inside checkInsAdLoaded in useAdStore**");
    const adUnit = useAdUnitStore.getState().getAdUnitByPlacement(adPlacement)!;
    // return callWebToAppFunction(
    //   WebToApp.IS_INS_AD_LOADED,
    //   adUnit.adClient,
    //   adUnit.adUnitId,
    //   AdPlacement.game_start_fsi,
    // );
    set((state) => ({
      adLoadAttempts: {
        ...state.adLoadAttempts,
        [adPlacement]: 1,
      },
    }));
    console.log(
      "webToAdCall checkInsAdLoaded for adClient",
      adUnit.adClient,
      "adUnitId",
      adUnit.adUnitId,
      "adPlacement",
      adPlacement,
    );
    return callWebToAppFunction(
      WebToApp.IS_INS_AD_LOADED,
      adUnit.adClient,
      adUnit.adUnitId,
      adPlacement,
    ) as boolean;
  },
  checkRdAdLoaded: (adPlacement) => {
    // console.log("**inside checkInsAdLoaded in useAdStore**");
    const adUnit = useAdUnitStore.getState().getAdUnitByPlacement(adPlacement)!;
    // return callWebToAppFunction(
    //   WebToApp.IS_INS_AD_LOADED,
    //   adUnit.adClient,
    //   adUnit.adUnitId,
    //   AdPlacement.game_start_fsi,
    // );
    set((state) => ({
      adLoadAttempts: {
        ...state.adLoadAttempts,
        [adPlacement]: 1,
      },
    }));
    console.log(
      "webToAdCall checkRdAdLoaded for adClient",
      adUnit.adClient,
      "adUnitId",
      adUnit.adUnitId,
      "adPlacement",
      adPlacement,
    );
    return callWebToAppFunction(
      WebToApp.IS_RD_AD_LOADED,
      adUnit.adClient,
      adUnit.adUnitId,
      adPlacement,
    ) as boolean;
  },
  setTemporaryPlacementFlag: (flagVal) =>
    set({ temporaryPlacementFlag: flagVal }),
  setTemporaryPlacementFlag_replay: (flagVal) =>
    set({ temporaryPlacementFlag_replay: flagVal }),
}));

export default useAdStore;

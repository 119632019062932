// src/utils/androidListeners.js

import { AdFormat, AdPlacement } from "../constants/AdConstants";
import { EventTypes, MODALS, WebToApp } from "../constants/Constants";
import useSessionStore from "../store/useSessionStore";
import usePageStore from "../store/usePageStore";
import { GamePopups, Pages } from "../constants/PageConstants";
import useAdStore from "../store/useAdStore";
import { Dispatch, RefObject, SetStateAction } from "react";
import useBannerAdStore from "../store/useBannerAdStore";
import { EventInfo } from "../types/window";
import { HelperTypes } from "../pages/GameIframe";
import { callWebToAppFunction } from "./androidCallers";
import useEnvStore from "../store/useEnvStore";
import { checkOnline } from "../services/backend-api";

// export const testFunctionCalledFromAndroid = (
//   passedParam,
//   iframeRef,
//   setShowContentOnAndroidCall
//   //setShowAd
// ) => {
//   console.log(
//     "this is a function was called from android with parameter",
//     passedParam
//   );
//   setShowContentOnAndroidCall(
//     `testfunctioncalledfromandroid was called with parameter ${passedParam}`
//   );
//   if (iframeRef && iframeRef.current.contentWindow) {
//     iframeRef.current.contentWindow.c3_callFunction("game_pause", []);
//     setShowContentOnAndroidCall(
//       `testfunctioncalledfromandroid was called with parameter ${passedParam}. yayy!`
//     );
//     // setShowAd(true);
//     // setTimeout(() => {
//     //   setShowAd(false);
//     //   console.log(
//     //     "iframerefcurrentcontentwinodw",
//     //     iframeRef.current.contentWindow
//     //   );
//     //   iframeRef.current.contentWindow.c3_callFunction("game_resume", []);
//     // }, 4000);
//   }
// };

const userLeftApplication = (iframeRef: RefObject<HTMLIFrameElement>) => {
  console.log("userLeftApplication: origin, destination");
  // iframeRef.current.contentWindow.c3_callFunction("game_pause", []);
  if (
    iframeRef &&
    iframeRef.current &&
    useSessionStore.getState().gameState === "playing"
  ) {
    const srcOrigin = new URL(iframeRef.current.src).origin;
    iframeRef.current?.contentWindow?.postMessage(
      { funcName: "game_pause" },
      srcOrigin,
    );
    useSessionStore.getState().setGameState("autopaused");
  }
  const { destroyBNAd } = useBannerAdStore.getState();
  destroyBNAd();
};

const userResumeApplication = async (
  iframeRef: RefObject<HTMLIFrameElement>,
) => {
  console.log("userResumeApplication: origin, destination");
  // iframeRef.current.contentWindow.c3_callFunction("game_resume", []);
  useEnvStore.getState().setIsOnline(await checkOnline());
  if (
    iframeRef &&
    iframeRef.current &&
    useEnvStore.getState().isOnline &&
    useSessionStore.getState().gameState === "autopaused"
  ) {
    const srcOrigin = new URL(iframeRef.current.src).origin;
    iframeRef.current?.contentWindow?.postMessage(
      { funcName: "game_resume" },
      srcOrigin,
    );
    useSessionStore.getState().setGameState("playing");
  }
  const { loadBNAd, setBnAdFailedDueToOffline } = useBannerAdStore.getState();
  setBnAdFailedDueToOffline(!useEnvStore.getState().isOnline);
  if (useEnvStore.getState().isOnline) {
    loadBNAd();
  }
};

const adSdkNotInitialised = (
  mediation: string,
  setShowTestOutput: Dispatch<SetStateAction<string>>,
) => {
  setShowTestOutput(
    `androidListeners: window.adSdkNotInitialised called with mediation ${mediation}`,
  );
};

const onRDAdDisplayed = async (
  mediation: string,
  adUnit: string,
  placement: string,
  iframeRef: RefObject<HTMLIFrameElement>,
  setShowTestOutput: Dispatch<SetStateAction<string>>,
  helperFuns: HelperTypes,
) => {
  if (setShowTestOutput)
    setShowTestOutput(
      `onRDAdDisplayed called with mediation: ${mediation}, adUnit: ${adUnit}, placement: ${placement}`,
    );
  // console.log("On rewarded ad displayed successfuly analytics");
  const { gameSessionId } = useSessionStore.getState();

  useEnvStore.getState().setIsOnline(await checkOnline());
  const { loadRdAd } = useAdStore.getState();

  const { destroyBNAd } = useBannerAdStore.getState();
  if (mediation === "admob") {
    destroyBNAd();
  }

  const { game } = helperFuns ?? { game: undefined };
  const onRdAdDisplaySuccessEvent: EventInfo = {
    eventType: EventTypes.ad_show,
    eventProperties: {
      web_timestamp: Date.now(),
      internetstate: useEnvStore.getState().isOnline ? "online" : "offline",
      ad_type: AdFormat.Rewarded,
      game_name: game?.name,
      game_id: game?.id,
      placement: placement,
      mediation: mediation,
      game_session_id: gameSessionId,
      ad_unit: adUnit,
    },
  };
  callWebToAppFunction(
    WebToApp.ANALYTICS_LISTENER,
    "",
    "",
    "",
    onRdAdDisplaySuccessEvent,
    null,
    undefined,
  );

  const { sdkVersion } = useSessionStore.getState().homePageParams;
  if (!sdkVersion) loadRdAd(placement);

  if (iframeRef && iframeRef.current) {
    const srcOrigin = new URL(iframeRef.current.src).origin;
    iframeRef.current?.contentWindow?.postMessage(
      { funcName: "game_mute_allsound" },
      srcOrigin,
    );
  }
};

const onRDAdDisplayFailed = (
  mediation: string,
  adUnit: string,
  placement: string,
  setShowTestOutput: Dispatch<SetStateAction<string>>,
) => {
  setShowTestOutput(
    `onRDAdDisplayFailed called with mediation: ${mediation}, adUnit: ${adUnit}, placement: ${placement}`,
  );
};

const onRDAdDismissed = async (
  mediation: string,
  adUnit: string,
  placement: string,
  iframeRef: RefObject<HTMLIFrameElement>,
  setShowTestOutput: Dispatch<SetStateAction<string>>,
) => {
  if (setShowTestOutput)
    setShowTestOutput(
      `onRDAdDismissed called with mediation: ${mediation}, adUnit: ${adUnit}, placement: ${placement}`,
    );
  if (iframeRef && iframeRef.current) {
    const srcOrigin = new URL(iframeRef.current.src).origin;
    iframeRef.current?.contentWindow?.postMessage(
      { funcName: "game_restore_allsound" },
      srcOrigin,
    );
  }
  const { setInsAdLoaded, loadRdAd, setRdAdLoadRetryFunc } =
    useAdStore.getState();
  const { sdkVersion } = useSessionStore.getState().homePageParams;
  // resetLoadAttempts();
  setInsAdLoaded(placement, false);
  useEnvStore.getState().setIsOnline(await checkOnline());

  if (mediation === "admob") {
    const { loadBNAd, setBnAdFailedDueToOffline } = useBannerAdStore.getState();
    setBnAdFailedDueToOffline(!useEnvStore.getState().isOnline);
    if (useEnvStore.getState().isOnline) {
      loadBNAd();
    }
  }

  if (sdkVersion) {
    if (!useEnvStore.getState().isOnline) {
      setRdAdLoadRetryFunc(placement);
    } else {
      loadRdAd(placement);
    }
  }
};

const onRDAdLoadSucceeded = (
  mediation: string,
  adUnit: string,
  placement: string,
  setShowTestOutput: Dispatch<SetStateAction<string>>,
  helperFuns: HelperTypes,
) => {
  if (setShowTestOutput)
    setShowTestOutput(
      `onRDAdLoadSucceeded called with mediation: ${mediation}, adUnit: ${adUnit}, placement: ${placement}`,
    );
  console.log("On rewarded ad loaded successfuly");
  const { gameSessionId } = useSessionStore.getState();
  const { game } = helperFuns ?? { game: undefined };
  const onRdAdLoadSuccessEvent: EventInfo = {
    eventType: EventTypes.ad_load_success,
    eventProperties: {
      web_timestamp: Date.now(),
      internetstate: useEnvStore.getState().isOnline ? "online" : "offline",
      ad_type: AdFormat.Rewarded,
      game_name: game?.name,
      game_id: game?.id,
      placement: placement,
      mediation: mediation,
      game_session_id: gameSessionId,
      ad_unit: adUnit,
    },
  };
  callWebToAppFunction(
    WebToApp.ANALYTICS_LISTENER,
    "",
    "",
    "",
    onRdAdLoadSuccessEvent,
    null,
    undefined,
  );
};

const onRDAdLoadFailed = (
  mediation: string,
  adUnit: string,
  placement: string,
  setShowTestOutput: Dispatch<SetStateAction<string>>,
) => {
  setShowTestOutput(
    `onRDAdLoadFailed called with mediation: ${mediation}, adUnit: ${adUnit}, placement: ${placement}`,
  );
};

const onDistributeRewards = (
  mediation: string,
  adUnit: string,
  placement: string,
  iframeRef: RefObject<HTMLIFrameElement>,
  setShowTestOutput: Dispatch<SetStateAction<string>>,
) => {
  console.log("onDistributeRewards");
  if (setShowTestOutput)
    setShowTestOutput(
      `onDistributeRewards called with mediation: ${mediation}, adUnit: ${adUnit}, placement: ${placement}`,
    );
  const rewardParams = useSessionStore.getState().rewardParams;
  if (iframeRef && iframeRef.current) {
    const srcOrigin = new URL(iframeRef.current.src).origin;
    console.log("rewardParams", rewardParams);
    iframeRef.current?.contentWindow?.postMessage(
      { funcName: "game_fsr_dismissed", rewardParams },
      srcOrigin,
    );
  }
};

const onINSAdDisplayed = async (
  mediation: string,
  adUnit: string,
  placement: string,
  iframeRef: RefObject<HTMLIFrameElement>,
  setShowTestOutput: Dispatch<SetStateAction<string>>,
  helperFuns: HelperTypes,
) => {
  if (setShowTestOutput)
    setShowTestOutput(
      `onINSAdDisplayed called with mediation: ${mediation}, adUnit: ${adUnit}, placement: ${placement}`,
    );
  const { gameSessionId } = useSessionStore.getState();
  console.log(
    `onINSAdDisplayed called with mediation: ${mediation}, adUnit: ${adUnit}, placement: ${placement}`,
  );
  // console.log(
  //   "On ins ad displayed successfuly analytics for game session id",
  //   gameSessionId
  // );
  useEnvStore.getState().setIsOnline(await checkOnline());
  const { loadInsAd } = useAdStore.getState();

  const { destroyBNAd } = useBannerAdStore.getState();
  if (mediation === "admob") {
    destroyBNAd();
  }

  const { game } = helperFuns ?? { game: undefined };
  const onInsAdDisplaySuccessEvent: EventInfo = {
    eventType: EventTypes.ad_show,
    eventProperties: {
      web_timestamp: Date.now(),
      internetstate: useEnvStore.getState().isOnline ? "online" : "offline",
      ad_type: AdFormat.Interstitial,
      game_name: game?.name,
      game_id: game?.id,
      placement: placement,
      mediation: mediation,
      game_session_id: gameSessionId,
      ad_unit: adUnit,
    },
  };
  callWebToAppFunction(
    WebToApp.ANALYTICS_LISTENER,
    "",
    "",
    "",
    onInsAdDisplaySuccessEvent,
    null,
    undefined,
  );

  const { sdkVersion } = useSessionStore.getState().homePageParams;
  if (!sdkVersion) loadInsAd(placement);

  if (iframeRef && iframeRef.current) {
    const srcOrigin = new URL(iframeRef.current.src).origin;
    iframeRef.current?.contentWindow?.postMessage(
      { funcName: "game_mute_allsound" },
      srcOrigin,
    );
  }
};

const onINSAdDisplayFailed = async (
  mediation: string,
  adUnit: string,
  placement: string,
  setShowTestOutput: Dispatch<SetStateAction<string>>,
) => {
  if (setShowTestOutput)
    setShowTestOutput(
      `onINSAdDisplayFailed called with mediation: ${mediation}, adUnit: ${adUnit}, placement: ${placement}`,
    );
  // const { resetLoadAttempts, setInsAdLoaded, loadInsAd } = useAdStore.getState();
  // resetLoadAttempts();
  // setInsAdLoaded(false);
  // loadInsAd();
  const { setInsAdLoaded, loadInsAd, checkInsAdLoaded } = useAdStore.getState();
  const { isCurrentUserRewarded } = useSessionStore.getState();
  const { loadBNAd } = useBannerAdStore.getState();
  // resetLoadAttempts();
  setInsAdLoaded(placement, false);
  useEnvStore.getState().setIsOnline(await checkOnline());
  if (useEnvStore.getState().isOnline) {
    loadBNAd();
  }
  if (useEnvStore.getState().isOnline) {
    if (placement === AdPlacement.game_replay_fsi) {
      // console.log("onINSAdDismissed: refilling game_replay_fsi");
      loadInsAd(AdPlacement.game_replay_fsi);
    } else if (placement === AdPlacement.game_end_fsi) {
      // console.log("onINSAdDismissed: refilling game_end_fsi");
      loadInsAd(AdPlacement.game_end_fsi);
    } else if (placement === AdPlacement.game_start_fsi) {
      //console.log("onINSAdDismissed: refilling game_start_fsi");
      loadInsAd(AdPlacement.game_start_fsi);
    } else if (placement === AdPlacement.game_exit_fsi) {
      // console.log("onINSAdDismissed: refilling game_exit_fsi");
      loadInsAd(AdPlacement.game_exit_fsi);
    } else if (placement === AdPlacement.game_pause_fsi) {
      // console.log("onINSAdDismissed: refilling game_pause_fsi");
      loadInsAd(AdPlacement.game_pause_fsi);
    } else {
      return;
    }
  }
};

const onINSAdDismissed = async (
  mediation: string,
  adUnit: string,
  placement: string,
  helperFuns: HelperTypes,
  iframeRef: RefObject<HTMLIFrameElement>,
  setShowTestOutput: Dispatch<SetStateAction<string>>,
) => {
  if (setShowTestOutput)
    setShowTestOutput(
      `onINSAdDismissed called with mediation: ${mediation}, adUnit: ${adUnit}, placement: ${placement}`,
    );
  console.log(
    `onINSAdDismissed called with mediation: ${mediation}, adUnit: ${adUnit}, placement: ${placement}`,
  );
  if (iframeRef && iframeRef.current) {
    const srcOrigin = new URL(iframeRef.current.src).origin;
    iframeRef.current?.contentWindow?.postMessage(
      { funcName: "game_restore_allsound" },
      srcOrigin,
    );
  }

  if (mediation === "admob") {
    const { loadBNAd, setBnAdFailedDueToOffline } = useBannerAdStore.getState();
    useEnvStore.getState().setIsOnline(await checkOnline());
    setBnAdFailedDueToOffline(!useEnvStore.getState().isOnline);
    if (useEnvStore.getState().isOnline) {
      loadBNAd();
    }
  }

  const { setInsAdLoaded, loadInsAd, checkInsAdLoaded, setInsAdLoadRetryFunc } =
    useAdStore.getState();
  const { handleShowModal } = helperFuns ?? { handleShowModal: () => {} };
  const { isCurrentUserRewarded } = useSessionStore.getState();
  // resetLoadAttempts();
  const { sdkVersion } = useSessionStore.getState().homePageParams;
  setInsAdLoaded(placement, false);
  if (sdkVersion) {
    useEnvStore.getState().setIsOnline(await checkOnline());
    if (!useEnvStore.getState().isOnline) {
      setInsAdLoadRetryFunc(placement);
    } else {
      if (placement === AdPlacement.game_replay_fsi) {
        // console.log("onINSAdDismissed: refilling game_replay_fsi");
        loadInsAd(AdPlacement.game_replay_fsi);
      } else if (placement === AdPlacement.game_end_fsi) {
        // console.log("onINSAdDismissed: refilling game_end_fsi");
        loadInsAd(AdPlacement.game_end_fsi);
      } else if (placement === AdPlacement.game_start_fsi) {
        //console.log("onINSAdDismissed: refilling game_start_fsi");
        loadInsAd(AdPlacement.game_start_fsi);
      } else if (placement === AdPlacement.game_exit_fsi) {
        // console.log("onINSAdDismissed: refilling game_exit_fsi");
        loadInsAd(AdPlacement.game_exit_fsi);
      } else if (placement === AdPlacement.game_pause_fsi) {
        // console.log("onINSAdDismissed: refilling game_pause_fsi");
        loadInsAd(AdPlacement.game_pause_fsi);
      } else {
        return;
      }
    }
  }
};

const onINSAdLoadSucceeded = (
  mediation: string,
  adUnit: string,
  placement: string,
  setShowTestOutput: Dispatch<SetStateAction<string>>,
  helperFuns: HelperTypes,
) => {
  if (setShowTestOutput)
    setShowTestOutput(
      `onINSAdLoadSucceeded called with mediation: ${mediation}, adUnit: ${adUnit}, placement: ${placement}`,
    );
  console.log("webToAdCall On ins ad loaded successfuly for", placement);
  const { gameSessionId } = useSessionStore.getState();
  // console.log("On ins ad loaded successfuly for 2", placement);
  const { game } = helperFuns ?? { game: undefined };
  // console.log("On ins ad loaded successfuly for 3", placement);
  const onInsAdSuccessEvent: EventInfo = {
    eventType: EventTypes.ad_load_success,
    eventProperties: {
      web_timestamp: Date.now(),
      internetstate: useEnvStore.getState().isOnline ? "online" : "offline",
      ad_type: AdFormat.Interstitial,
      game_name: game?.name,
      game_id: game?.id,
      placement: placement,
      mediation: mediation,
      game_session_id: gameSessionId,
      ad_unit: adUnit,
    },
  };
  // console.log("On ins ad loaded successfuly line 3");
  callWebToAppFunction(
    WebToApp.ANALYTICS_LISTENER,
    "",
    "",
    "",
    onInsAdSuccessEvent,
    null,
    undefined,
  );
  // console.log("On ins ad loaded successfuly line 4");
  // console.log(
  //   `onINSAdLoadSucceeded called with mediation: ${mediation}, adUnit: ${adUnit}, placement: ${placement}`,
  // );
  const { setInsAdLoaded, resetLoadAttempts, loadInsAd } =
    useAdStore.getState();
  // extra calls
  // loadInsAd(AdPlacement.game_exit_fsi);
  // loadInsAd(AdPlacement.game_replay_fsi);
  // loadInsAd(AdPlacement.game_start_fsi);
  // loadInsAd(AdPlacement.game_end_fsi);
  setInsAdLoaded(placement, true);
  resetLoadAttempts(placement);
  // console.log("On ins ad loaded successfuly line 5");
};

const onINSAdLoadFailed = async (
  mediation: string,
  adUnit: string,
  placement: string,
  setShowTestOutput: Dispatch<SetStateAction<string>>,
) => {
  if (setShowTestOutput)
    setShowTestOutput(
      `onBNAdLoadFailed called with mediation: ${mediation}, adUnit: ${adUnit}, placement: ${placement}`,
    );
  console.log(
    `onINSAdLoadFailed called with mediation: ${mediation}, adUnit: ${adUnit}, placement: ${placement}`,
  );
  const { loadInsAd, maxLoadAttempts, incrementLoadAttempts } =
    useAdStore.getState();
  const currentAttempt = useAdStore.getState().adLoadAttempts[placement] ?? 1;
  console.log("current attempt for ", placement, currentAttempt);
  useEnvStore.getState().setIsOnline(await checkOnline());
  if (useEnvStore.getState().isOnline) {
    if (placement === AdPlacement.game_replay_fsi) {
      if (currentAttempt < maxLoadAttempts) {
        // console.log("onINSAdLoadFailed: refilling game_replay_fsi");
        incrementLoadAttempts(placement);
        setTimeout(() => loadInsAd(AdPlacement.game_replay_fsi), 1000);
      }
    } else if (placement === AdPlacement.game_end_fsi) {
      // console.log("onINSAdLoadFailed: refilling game_end_fsi");
      if (currentAttempt < maxLoadAttempts) {
        // console.log("onINSAdLoadFailed: refilling game_replay_fsi");
        incrementLoadAttempts(placement);
        setTimeout(() => loadInsAd(AdPlacement.game_end_fsi), 1000);
      }
    } else if (placement === AdPlacement.game_start_fsi) {
      //console.log("onINSAdLoadFailed: refilling game_start_fsi");
      if (currentAttempt < maxLoadAttempts) {
        // console.log("onINSAdLoadFailed: refilling game_replay_fsi");
        incrementLoadAttempts(placement);
        setTimeout(() => loadInsAd(AdPlacement.game_start_fsi), 1000);
      }
    } else if (placement === AdPlacement.game_exit_fsi) {
      // console.log("onINSAdLoadFailed: refilling game_exit_fsi");
      if (currentAttempt < maxLoadAttempts) {
        // console.log("onINSAdLoadFailed: refilling game_replay_fsi");
        incrementLoadAttempts(placement);
        setTimeout(() => loadInsAd(AdPlacement.game_exit_fsi), 1000);
      }
    } else if (placement === AdPlacement.game_pause_fsi) {
      // console.log("onINSAdLoadFailed: refilling game_pause_fsi");
      if (currentAttempt < maxLoadAttempts) {
        // console.log("onINSAdLoadFailed: refilling game_replay_fsi");
        incrementLoadAttempts(placement);
        setTimeout(() => loadInsAd(AdPlacement.game_pause_fsi), 1000);
      }
    } else {
      return;
    }
  }
};

const onBNAdExpanded = (
  mediation: string,
  adUnit: string,
  placement: string,
  setShowTestOutput: Dispatch<SetStateAction<string>>,
) => {
  setShowTestOutput(
    `onBNAdExpanded called with mediation: ${mediation}, adUnit: ${adUnit}, placement: ${placement}`,
  );
};

const onBNAdCollapsed = (
  mediation: string,
  adUnit: string,
  placement: string,
  setShowTestOutput: Dispatch<SetStateAction<string>>,
) => {
  setShowTestOutput(
    `onBNAdCollapsed called with mediation: ${mediation}, adUnit: ${adUnit}, placement: ${placement}`,
  );
};

const onBNAdClicked = (
  mediation: string,
  adUnit: string,
  placement: string,
  setShowTestOutput: Dispatch<SetStateAction<string>>,
) => {
  setShowTestOutput(
    `onBNAdClicked called with mediation: ${mediation}, adUnit: ${adUnit}, placement: ${placement}`,
  );
};

const onBNAdLoadSucceeded = (
  mediation: string,
  adUnit: string,
  placement: string,
  setShowTestOutput: Dispatch<SetStateAction<string>>,
  helperFuns: HelperTypes,
) => {
  if (setShowTestOutput)
    setShowTestOutput(
      `onBNAdLoadSucceeded called with mediation: ${mediation}, adUnit: ${adUnit}, placement: ${placement}`,
    );
  // console.log("On banner ad loaded successfuly");
};

const onBNAdLoadFailed = (
  mediation: string,
  adUnit: string,
  placement: string,
  setShowTestOutput: Dispatch<SetStateAction<string>>,
) => {
  setShowTestOutput(
    `onBNAdLoadFailed called with mediation: ${mediation}, adUnit: ${adUnit}, placement: ${placement}`,
  );
};

const invalidBNAdPosition = (
  x: number,
  y: number,
  setShowTestOutput: Dispatch<SetStateAction<string>>,
) => {
  setShowTestOutput(`invalidBNAdPosition called with x: ${x}, y: ${y}`);
};

export const setupAndroidListeners = (
  iframeRef?: RefObject<HTMLIFrameElement>,
  // setShowContentOnAndroidCall,
  // setShowAd,
  helperFuns?: HelperTypes,
  setShowTestOutput?: Dispatch<SetStateAction<string>>,
) => {
  window.adSdkNotInitialised = (mediation) => {
    adSdkNotInitialised(mediation, setShowTestOutput!);
  };

  window.userLeftApplication = () => {
    userLeftApplication(iframeRef!);
    setShowTestOutput!(`androidListeners: window.userLeftApplication called`);
  };

  window.userResumeApplication = () => {
    userResumeApplication(iframeRef!);
    setShowTestOutput!(`androidListeners: window.userResumeApplication called`);
  };

  window.androidBack = () => {
    console.log(
      "window.androidBack called. Current page",
      Pages[usePageStore.getState().page],
    );
    // console.log(
    //   "window.androidBack called. Current popup",
    //   usePageStore.getState().popup,
    // );
    const { showInsAd } = useAdStore.getState();
    if (
      usePageStore.getState().page === Pages.GAME &&
      usePageStore.getState().popup === GamePopups.EXIT
    ) {
      usePageStore.getState().setPopup(GamePopups.EXIT);
      showInsAd(AdPlacement.game_exit_fsi);
    }
    if (usePageStore.getState().page === Pages.HOME) {
      window.Android.closeWebview();
    }
    setShowTestOutput!(`androidListeners: window.androidBack called`);
  };

  // window.testfunctioncalledfromandroid = (passedParam) => {
  //   testFunctionCalledFromAndroid(
  //     passedParam,
  //     //   iframeRef,
  //     null,
  //     setShowTestOutput
  //     //setShowAd
  //   );
  // };

  window.onRDAdDisplayed = (mediation, adUnit, placement) => {
    onRDAdDisplayed(
      mediation,
      adUnit,
      placement,
      iframeRef!,
      setShowTestOutput!,
      helperFuns,
    );
  };

  window.onRDAdDisplayFailed = (mediation, adUnit, placement) => {
    onRDAdDisplayFailed(mediation, adUnit, placement, setShowTestOutput!);
  };

  window.onRDAdDismissed = (mediation, adUnit, placement) => {
    onRDAdDismissed(
      mediation,
      adUnit,
      placement,
      iframeRef!,
      setShowTestOutput!,
    );
  };

  window.onRDAdLoadSucceeded = (mediation, adUnit, placement) => {
    onRDAdLoadSucceeded(
      mediation,
      adUnit,
      placement,
      setShowTestOutput!,
      helperFuns,
    );
  };

  window.onRDAdLoadFailed = (mediation, adUnit, placement) => {
    onRDAdLoadFailed(mediation, adUnit, placement, setShowTestOutput!);
  };

  window.onDistributeRewards = (mediation, adUnit, placement) => {
    onDistributeRewards(
      mediation,
      adUnit,
      placement,
      iframeRef!,
      setShowTestOutput!,
    );
  };

  window.onINSAdDisplayed = (mediation, adUnit, placement) => {
    onINSAdDisplayed(
      mediation,
      adUnit,
      placement,
      iframeRef!,
      setShowTestOutput!,
      helperFuns,
    );
  };

  window.onINSAdDisplayFailed = (mediation, adUnit, placement) => {
    onINSAdDisplayFailed(mediation, adUnit, placement, setShowTestOutput!);
  };

  window.onINSAdDismissed = (mediation, adUnit, placement) => {
    onINSAdDismissed(
      mediation,
      adUnit,
      placement,
      helperFuns,
      iframeRef!,
      setShowTestOutput!,
    );
  };

  window.onINSAdLoadSucceeded = (mediation, adUnit, placement) => {
    onINSAdLoadSucceeded(
      mediation,
      adUnit,
      placement,
      setShowTestOutput!,
      helperFuns,
    );
  };

  window.onINSAdLoadFailed = (mediation, adUnit, placement) => {
    onINSAdLoadFailed(mediation, adUnit, placement, setShowTestOutput!);
  };

  window.onBNAdExpanded = (mediation, adUnit, placement) => {
    onBNAdExpanded(mediation, adUnit, placement, setShowTestOutput!);
  };

  window.onBNAdCollapsed = (mediation, adUnit, placement) => {
    onBNAdCollapsed(mediation, adUnit, placement, setShowTestOutput!);
  };

  window.onBNAdClicked = (mediation, adUnit, placement) => {
    onBNAdClicked(mediation, adUnit, placement, setShowTestOutput!);
  };

  window.onBNAdLoadSucceeded = (mediation, adUnit, placement) => {
    onBNAdLoadSucceeded(
      mediation,
      adUnit,
      placement,
      setShowTestOutput!,
      helperFuns,
    );
  };

  window.onBNAdLoadFailed = (mediation, adUnit, placement) => {
    onBNAdLoadFailed(mediation, adUnit, placement, setShowTestOutput!);
  };

  window.invalidBNAdPosition = (x, y) => {
    invalidBNAdPosition(x, y, setShowTestOutput!);
  };

  return () => {
    window.adSdkNotInitialised = null;
    window.userLeftApplication = null;
    window.userResumeApplication = null;
    window.androidBack = null;
    // window.testfunctioncalledfromandroid = null;
    window.onRDAdDisplayed = null;
    window.onRDAdDisplayFailed = null;
    window.onRDAdDismissed = null;
    window.onRDAdLoadSucceeded = null;
    window.onRDAdLoadFailed = null;
    window.onDistributeRewards = null;
    window.onINSAdDisplayed = null;
    window.onINSAdDisplayFailed = null;
    window.onINSAdDismissed = null;
    window.onINSAdLoadSucceeded = null;
    window.onINSAdLoadFailed = null;
    window.onBNAdExpanded = null;
    window.onBNAdCollapsed = null;
    window.onBNAdClicked = null;
    window.onBNAdLoadSucceeded = null;
    window.onBNAdLoadFailed = null;
    window.invalidBNAdPosition = null;
  };
};
